import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import AboutUs from './pages/AboutUs';
import Login from './pages/login/Login';
import Feedback from './pages/feedBack/Feedback';
import Layout from './components/layout/Layout';
import ForgotPassword from './pages/forgotPassword/ForgotPassword';
import SubscribeUnsubscribe from './components/EmailPreferencesPage/SubscribeUnsubscribe';
import SupportPage from './pages/supportPage/SupportPage';
function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin/login" element={<Login />} />
          <Route path="/admin/forgot-password" element={<ForgotPassword />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/subscribe" element={<SubscribeUnsubscribe />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route
            path="/admin/feedback"
            element={
              <Layout>
                <Feedback />
              </Layout>
            }
          />
          <Route path="/support" element={<SupportPage />} />
        </Routes>
        {/* <Footer /> */}
      </div>
    </Router>
  );
}

export default App;
