import React from 'react';
import { Field, ErrorMessage } from 'formik';
import styles from './TextAreaField.module.css';

function TextAreaField({
  name,
  label,
  placeholder = '',
  required = false,
  disabled = false,
  className = '',
  error,
  rows = 4,
}) {
  return (
    <div className={`${styles.textAreaContainer} ${className}`}>
      {label && <label className={styles.label}>{label}</label>}
      <Field
        as="textarea"
        name={name}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        rows={rows}
        className={`${styles.textArea} ${error ? styles.errorInput : ''}`}
      />
      <ErrorMessage name={name} component="span" className={styles.error} />
    </div>
  );
}

export default TextAreaField;
