import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './SubscribeUnsubscribe.module.css';
import { updateEmailSubscriptionStatus } from '../../api/adminApi';
import emailIcon from '../../assets/flat-color-icons_invite.svg';
import Loader from '../loader/Loader';

const subscriptionDescriptions = {
  GUARDIANSHIP_REQUEST:
    'By unsubscribing from Guardian Updates Notifications, you will no longer receive notifications about Guardianship request updates.',
  RECIPIENT_LIFEWINK_NOTIFICATION:
    'By unsubscribing from Recipient LifeWink Notifications, you will no longer receive emails regarding updates or notifications related to the LifeWinks you are a recipient of.',
  APPOINTED_AS_STEWARD:
    'By unsubscribing from Appointed as Steward Notifications, you will no longer receive emails when someone appoints you as their Steward.',
  APPOINTED_AS_STEWARD_WHEN_MEMBER_ALIVE:
    'By unsubscribing from Appointed as Steward (When Member is Alive) Notifications, you will no longer receive notifications about being appointed as a Steward while the Member is alive.',
  APPOINTED_AS_STEWARD_WHEN_MEMBER_PASSED:
    'By unsubscribing from Appointed as Steward (When Member has Passed) Notifications, you will no longer receive notifications about being appointed as a Steward when a Member has passed.',
  REMOVED_STEWARDSHIP:
    'By unsubscribing from Removed from Stewardship Notifications, you will no longer receive updates regarding your removal from Stewardship.',
  STEWARD_NOTIFICATION_ON_LIFEWINK_DELETE:
    'By unsubscribing from Steward Notification on LifeWink Deletion, you will no longer receive notifications about LifeWink deletions as a Steward.',
  STEWARDSHIP_NOTIFICATION_ON_SEND_NOW:
    'By unsubscribing from Stewardship Notification on "Send Now", you will no longer receive notifications triggered by the "Send Now" action for Steward notifications.',
  DELETE_USER:
    'By unsubscribing from Delete User Notifications, you will no longer receive notifications about user deletions.',
  REMOVE_STEWARD:
    'By unsubscribing from Remove Steward Notifications, you will no longer receive notifications about the removal of Stewards.',
  STEWARDSHIP_UPDATE:
    'By unsubscribing from Stewardship Updates Notifications, you will no longer receive notifications about Stewardship updates.',
  GUARDIAN_UPDATE:
    'By unsubscribing from Guardian Updates Notifications, you will no longer receive notifications about Guardian updates.',
  GUARDIAN_DELETE:
    'By unsubscribing from Guardian Delete Notifications, you will no longer receive updates or notifications about the deletion of a guardian associated with your account or responsibilities.',
};

const SubscribeUnsubscribe = () => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [message, setMessage] = useState('');
  const [subscriptionType, setSubscriptionType] = useState('');
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const encryptedSubscriptionStatus = queryParams.get(
    'encryptedSubscriptionStatus',
  );

  const handleSubscription = async (action) => {
    if (!encryptedSubscriptionStatus) {
      setMessage('Invalid subscription status');
      return;
    }

    try {
      setLoading(true);
      const params = {
        encryptedSubscriptionStatus: encryptedSubscriptionStatus,
        action: action,
      };

      const response = await updateEmailSubscriptionStatus(params);

      const subscriptionStatus = JSON.parse(response.data);

      if (
        subscriptionStatus &&
        subscriptionStatus.metaData &&
        subscriptionStatus.metaData.isSubscribed !== undefined
      ) {
        setIsSubscribed(subscriptionStatus.metaData.isSubscribed);
        setMessage(
          action === 'subscribe'
            ? 'You have successfully subscribed!'
            : 'You have unsubscribed successfully!',
        );
      } else {
        setMessage('Error updating subscription status');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error updating subscription status:', error);
      setMessage('Error occurred while updating subscription status');
    }
  };

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      if (!encryptedSubscriptionStatus) {
        setMessage('Invalid subscription status');
        return;
      }

      try {
        setLoading(true);
        const params = {
          encryptedSubscriptionStatus: encryptedSubscriptionStatus,
        };
        const response = await updateEmailSubscriptionStatus(params);

        const subscriptionStatus = JSON.parse(response.data);

        const type = subscriptionStatus.pk?.split('@').pop();

        setSubscriptionType(type);

        if (
          subscriptionStatus &&
          subscriptionStatus.metaData &&
          subscriptionStatus.metaData.isSubscribed !== undefined
        ) {
          setIsSubscribed(subscriptionStatus.metaData.isSubscribed);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching subscription status:', error);
      }
    };

    if (encryptedSubscriptionStatus) {
      fetchSubscriptionStatus();
    }
  }, [encryptedSubscriptionStatus]);

  return loading ? (
    <div className={styles.container} style={{ minHeight: '80vh' }}>
      <Loader />
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.header}>
        <img
          src="https://lifewink-common.s3.us-east-2.amazonaws.com/emailAssets/lifewink_logo.png"
          className={styles.logo}
          alt="LifeWink Logo"
        ></img>
      </div>
      <div className={styles.subscriptionWrap}>
        <h1>
          {isSubscribed
            ? 'We are sorry to see you go!'
            : 'Great to have you stay!'}
        </h1>
        <img src={emailIcon} alt="" />
        <h2 className={styles.title}>
          {isSubscribed
            ? 'Are you sure about unsubscribing?'
            : 'Are you sure about subscribing?'}
        </h2>
        <p>
          {subscriptionDescriptions[subscriptionType] ||
            'Subscription type not recognized.'}
        </p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            marginBottom: '30px',
          }}
        >
          {isSubscribed ? (
            <span
              className={`${styles.subscribe} ${styles.unsubscribe}`}
              onClick={() => handleSubscription('unsubscribe')}
            >
              Unsubscribe
            </span>
          ) : (
            <span
              className={styles.subscribe}
              onClick={() => handleSubscription('subscribe')}
            >
              Subscribe
            </span>
          )}
        </div>
        {message && (
          <p
            className={`${styles.message} ${isSubscribed ? styles.success : styles.error}`}
          >
            {message}
          </p>
        )}
      </div>
    </div>
  );
};

export default SubscribeUnsubscribe;
