// src/api/adminApi.js
import apiClient from './apiClient';

// Fetch data from /admin endpoint with optional query parameters
export const fetchFeedbackData = async (params = {}) => {
  try {
    const response = await apiClient.get('/admin/feedback', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching admin data:', error);
    throw error;
  }
};

export const updateEmailSubscriptionStatus = async (params = {}) => {
  try {
    const response = await apiClient.get('/subscription', { params });
    return response.data;
  } catch (error) {
    console.error('Error updating subscription status:', error);
    throw error;
  }
};

export const submitSupportRequest = async (body = {}) => {
  try {
    const response = await apiClient.post('/support', body);
    return response.data;
  } catch (error) {
    console.error('Error submitting support request:', error);
    throw error;
  }
};
