import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import backButton from '../../assets/support/backButton.svg';
import happyIcon from '../../assets/support/happy.svg';
import regularIcon from '../../assets/support/regular.svg';
import sadIcon from '../../assets/support/sad.svg';
import styles from './Support.module.css';
import TextAreaField from '../../components/textArea/TextAreaField';
import { submitSupportRequest } from '../../api/adminApi';
import { useNavigate } from 'react-router-dom';

function SupportPage() {
  const navigate = useNavigate();
  const improvementAreas = [
    'Log In or Sign Up',
    'Creating LifeWinks',
    'Building AI LifeWinks',
    'Receiving LifeWinks',
    'Customer Support',
    'Send Now Exceptions',
    'Steward Experience',
    'Guardian Experience',
    'My LifeWinks',
    'Receiving AI LifeWinks',
  ];

  const validationSchema = Yup.object({
    lifeWinkExperience: Yup.number().required('Please select an experience'),
    improvementAreas: Yup.array()
      .of(Yup.string())
      .min(1, 'Please select at least one improvement area'),
    suggestions: Yup.string(),
  });

  const handleSubmit = async (values, { resetForm }) => {
    try {
      await submitSupportRequest(values);
      resetForm();
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  return (
    <Formik
      initialValues={{
        lifeWinkExperience: '', // 0 for Happy, 1 for Regular, 2 for Sad
        improvementAreas: [], // Array of selected areas
        suggestions: '', // User suggestions
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, isSubmitting, isValid, touched }) => (
        <Form>
          <div className={styles.container}>
            <div className={styles.supportBox}>
              <div className={styles.title}>
                <img src={backButton} alt="" onClick={() => navigate(-1)} />
                <h3> Submit Feedback</h3>
              </div>
              <div className={styles.feedbackText}>
                <h3>We value your opinion</h3>
                <p>
                  Please share any and all suggestions and comments you have
                  about LifeWink.
                </p>
              </div>
              <div className={styles.experience}>
                <p>How is your LifeWink experience?</p>
                <div className={styles.icons}>
                  <img
                    src={happyIcon}
                    alt="Happy"
                    className={`${styles.icon} ${
                      values.lifeWinkExperience === 0 ? styles.greenIcon : ''
                    }`}
                    onClick={() => setFieldValue('lifeWinkExperience', 0)}
                  />
                  <img
                    src={regularIcon}
                    alt="Regular"
                    className={`${styles.icon} ${
                      values.lifeWinkExperience === 1 ? styles.yellowIcon : ''
                    }`}
                    onClick={() => setFieldValue('lifeWinkExperience', 1)}
                  />
                  <img
                    src={sadIcon}
                    alt="Sad"
                    className={`${styles.icon} ${
                      values.lifeWinkExperience === 2 ? styles.redIcon : ''
                    }`}
                    onClick={() => setFieldValue('lifeWinkExperience', 2)}
                  />
                </div>
              </div>
              <div className={styles.improvement}>
                <p>Tell us what can be improved?</p>
                <div className={styles.improvementGrid}>
                  {improvementAreas.map((area) => (
                    <div
                      key={area}
                      className={`${styles.improvementItem} ${
                        values.improvementAreas.includes(area)
                          ? styles.selectedItem
                          : ''
                      }`}
                      onClick={() => {
                        const selected = [...values.improvementAreas];
                        if (selected.includes(area)) {
                          setFieldValue(
                            'improvementAreas',
                            selected.filter((item) => item !== area),
                          );
                        } else {
                          setFieldValue('improvementAreas', [
                            ...selected,
                            area,
                          ]);
                        }
                      }}
                    >
                      <span className={styles.improvementAreas}>{area}</span>
                    </div>
                  ))}
                </div>
              </div>
              <TextAreaField
                name="suggestions"
                label="Your Feedback"
                placeholder="Share your thoughts..."
              />
              <button
                type="submit"
                className={`${styles.submitBtn} ${isSubmitting || !isValid || Object.keys(touched).length === 0 ? styles.disabledButton : ''}`}
                disabled={
                  isSubmitting || !isValid || Object.keys(touched).length === 0
                }
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SupportPage;
