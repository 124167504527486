import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';

const Calendar = ({ startDate, endDate, onChange }) => {
  const today = new Date();
  const [state, setState] = useState([
    {
      startDate: startDate ? new Date(startDate) : today,
      endDate: endDate ? new Date(endDate) : today,
      key: 'selection',
    },
  ]);

  useEffect(() => {
    setState([
      {
        startDate: startDate ? new Date(startDate) : today,
        endDate: endDate ? new Date(endDate) : today,
        key: 'selection',
      },
    ]);
  }, [startDate, endDate]);

  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    onChange(selection);
    setState([selection]);
  };
  console.log('state', state);

  return (
    <DateRange
      onChange={handleOnChange}
      displayMode="dateRange"
      showSelectionPreview={false}
      moveRangeOnFirstSelection={false}
      months={1}
      ranges={state}
      direction="horizontal"
      dateDisplayFormat="dd MMM, yyyy"
      editableDateInputs
      retainEndDateOnFirstSelection
      minDate={new Date('2023-01-01')}
      maxDate={new Date()}
    />
  );
};

Calendar.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default Calendar;
